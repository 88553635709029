<template>
  <a @click.prevent="toggle" href="#" class="mr-2 nav-link">
    <i class="jbsmd-globe d-lg-none"></i>
    <p>
      <span class="d-none d-lg-inline">{{ targetLg.toUpperCase() }}</span>
      <span class="d-lg-none d-md-block ml-2">{{ label }}</span>
    </p>
  </a>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapGetters("me", ["targetLg"]),
    label() {
      return this.targetLg === "en" ? "English" : "Français";
    }
  },
  methods: {
    ...mapActions("me", ["toggleLg"]),
    toggle() {
      this.$language.current = this.targetLg;
      this.toggleLg();
      this.$emit("lang-switched");
    }
  }
};
</script>
