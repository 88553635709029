<template>
  <div class="wrapper wrapper-full-page container-fluid">
    <div class="row">
      <div
        class="col-xl-4 col-md-6 px-5 d-flex flex-column justify-content-center min-vh-100"
      >
        <div class="text-center py-4">
          <router-link to="/login" class="navbar-brand"
            ><img
              :src="require(`@/assets/images/enroll_logo.svg`)"
              alt="Enroll Talent"
              height="60px"
            />
            <div class="separator"></div>
            <img
              :src="require(`@/assets/images/jobsmedia-logo.svg`)"
              class="jobsmedia-logo"
              alt="Jobsmedia"
              height="30px"
            />
          </router-link>
        </div>
        <router-view />
      </div>
      <div
        class="col-xl-8 col-md-6 d-none d-md-flex flex-column p-5 justify-content-between background-cover"
        :style="'background-image: url(\'' + backgroundCoverImg + '\');'"
      >
        <!-- Navbar -->
        <nav class="navbar navbar-expand-lg navbar-absolute fixed-top">
          <div class="container-fluid">
            <div
              class="collapse navbar-collapse justify-content-end"
              id="navigation"
            >
              <ul class="navbar-nav">
                <li class="nav-item">
                  <router-link to="/signup" class="nav-link">
                    <i class="nc-icon nc-book-bookmark"></i>
                    <translate>Register</translate>
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/login" class="nav-link">
                    <i class="nc-icon nc-tap-01"></i>
                    <translate>Login</translate>
                  </router-link>
                </li>
                <li class="nav-item">
                  <LanguageSwitcher class="text-white" />
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <!-- End Navbar -->
      </div>
    </div>
  </div>
</template>
<script>
import LanguageSwitcher from "@/layouts/components/LanguageSwitcher";
export default {
  components: { LanguageSwitcher },
  computed: {
    backgroundCoverImg() {
      switch (this.$route.query.source) {
        default:
          return require(`@/assets/images/cover/enroll.png`);
        case "lemarche":
          return require(`@/assets/images/cover/lemarche.png`);
        case "comptafinance":
          return require(`@/assets/images/cover/comptafinance.png`);
        case "tonmetier":
          return require(`@/assets/images/cover/tonmetier.png`);
        case "emploisrh":
          return require(`@/assets/images/cover/emploisrh.png`);
        case "emploisti":
          return require(`@/assets/images/cover/emploisti.png`);
        case "lavente":
          return require(`@/assets/images/cover/lavente.png`);
        case "lesjuristes":
          return require(`@/assets/images/cover/lesjuristes.png`);
        case "emploisadmin":
          return require(`@/assets/images/cover/emploisadmin.png`);
        case "educemplois":
          return require(`@/assets/images/cover/educemplois.png`);
      }
    }
  }
};
</script>
